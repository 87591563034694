import { GroupedOption } from '@shared/components/group-select-new';
import { Option } from '@shared/components/select/Select.types';
import { GroupOptions } from '@shared/constants/options';
import { ConfigAgencyConstants, ConfigCore, LocalisationConstant } from '@shared/models/config';
import { IUser } from '@shared/models/IUser';

export type ValueObjectOption<T extends Id = Id> = { value: T; name: string; pro?: boolean };
export type IdObjectOption<T extends Id = Id> = { id: T; name: string; pro?: boolean };
export type ObjectOptionsKey = Id;

export type StringObjectOptions = { [key: ObjectOptionsKey]: string };
export type IdObjectOptions<T extends Id = Id> = { [key: ObjectOptionsKey]: IdObjectOption<T> };
export type ValueObjectOptions<T extends Id = Id> = { [key: ObjectOptionsKey]: ValueObjectOption<T> };

export type StringArrayOptions = string[];
export type IdArrayOptions<T extends Id = Id> = IdObjectOption<T>[];
export type ValueArrayOptions<T extends Id = Id> = ValueObjectOption<T>[];

export type OptionsItem<T = Id> = { id: T; label: string; show?: boolean };

/* ------------------- */

/**
 * Converts an array of options into an array of grouped options with a single group.
 * This is useful when you want a multiselect dropdown with a search feature.
 *
 * @template T - The type of the identifier in the options. It defaults to `Id`.
 *
 * @param {Option<T>[]} options - The options to be grouped.
 * @param {string} [label=''] - The label for the group. Defaults to an empty string.
 *
 * @returns {GroupedOption<T>[]} - An array of grouped options. Each group is an object with a label and an array of options. In this case, there will be only one group with the provided label and the options.
 *
 * @example
 * // If the function is called with the following parameters:
 * optionsToFlatGroupOptions([{ id: 1, label: 'Option 1' }, { id: 2, label: 'Option 2' }], 'Group 1')
 * // It will return:
 * // [
 * //   {
 * //     label: 'Group 1',
 * //     options: [
 * //       { id: 1, label: 'Option 1' },
 * //       { id: 2, label: 'Option 2' }
 * //     ]
 * //   }
 * // ]
 */
export const optionsToFlatGroupOptions = <T = Id>(options: Option<T>[], label?: string): GroupedOption<T>[] => {
  return [{ label: label || '', options }];
};

// TODO: Deprecated - Use new stringObjectToOptions util below
export const objectToOptions = (objectOptions?: StringObjectOptions, labelKey = false) => {
  const options: Option<string>[] = [];

  if (objectOptions) {
    for (const [key, value] of Object.entries(objectOptions)) {
      if (labelKey) {
        options.push({ id: value, label: key });
      } else {
        options.push({ id: key, label: value });
      }
    }
  }

  return options;
};

// TODO: Deprecated - Use new stringObjectToNumberOptions util below
export const objectToNumberOptions = (objectOptions?: StringObjectOptions, labelKey = false) => {
  const options: Option<number>[] = [];

  if (objectOptions) {
    for (const [key, value] of Object.entries(objectOptions)) {
      if (labelKey) {
        options.push({ id: Number(value), label: key });
      } else {
        options.push({ id: Number(key), label: value });
      }
    }
  }

  return options;
};

// --

export const stringObjectToOptions = <T extends string = string>(objectOptions?: StringObjectOptions): Option<T>[] => {
  if (!objectOptions) {
    return [];
  }

  return Object.entries(objectOptions).map(([key, value]) => ({ id: key as T, label: value }));
};

export const stringObjectToNumberOptions = <T extends number = number>(
  objectOptions?: StringObjectOptions
): Option<T>[] => {
  if (!objectOptions) {
    return [];
  }

  return Object.entries(objectOptions).map(([key, value]) => ({ id: Number(key) as T, label: value }));
};

export const stringArrayToOptions = <T extends string = string>(arrayOptions?: StringArrayOptions): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: option as T, label: option }));
};

export const stringArrayToNumberOptions = <T extends number = number>(
  arrayOptions?: StringArrayOptions
): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: Number(option) as T, label: option }));
};

// --

export const idArrayToOptions = <T extends string = string>(arrayOptions?: IdArrayOptions): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: option.id.toString() as T, label: option.name }));
};

export const idArrayToNumberOptions = <T extends number = number>(arrayOptions?: IdArrayOptions): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: Number(option.id) as T, label: option.name }));
};

// --

export const valueArrayToOptions = <T extends string = string>(arrayOptions?: ValueArrayOptions): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: option.value.toString() as T, label: option.name }));
};

export const valueArrayToNumberOptions = <T extends number = number>(arrayOptions?: ValueArrayOptions): Option<T>[] => {
  if (!arrayOptions || !arrayOptions.length) {
    return [];
  }

  return arrayOptions.map((option) => ({ id: Number(option.value) as T, label: option.name }));
};

// --

export const sortSelectedAccordingToOptions = <T = unknown>(selected: T[], options: Option<T>[]) =>
  options.filter((option) => selected.includes(option.id)).map((option) => option.id);

/* ------------------- */

export const availableLettingTypeOptionsToSelectOptions = (types?: StringArrayOptions): Option<number>[] => {
  if (!types) return [];

  return types.map((option, idx) => ({ label: option, id: idx }));
};

export const buildingTypesToVisibleOptions = (visibleTypes: Id[], types?: StringObjectOptions) => {
  if (!types) return [];

  const visibleTypesSet = new Set(visibleTypes);

  return objectToOptions(types).filter((option) => visibleTypesSet.has(option.id));
};

export const sourceOptionsSelectOptions = (sourceOptions?: LocalisationConstant['sourceOptions']): Option<number>[] => {
  if (!sourceOptions) return [];

  return sourceOptions.map(({ label, value }) => ({ label, id: value }));
};

export const teamsListToSelectOptions = (teams?: ConfigCore['teams']): Option<string>[] => {
  if (!teams) return [];

  return teams.map(({ id, name }) => ({ label: name, id: id.toString() }));
};

export const filterOptions = (options: Option[], whitelist?: Id[], sort?: boolean) => {
  if (!Array.isArray(options)) {
    return [];
  }

  if (Array.isArray(whitelist) && whitelist.length) {
    options = options.filter(function (option) {
      return whitelist.indexOf(option.id) !== -1;
    });
  }

  if (typeof sort === 'boolean' && sort) {
    options = options.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }

      return a.label > b.label ? 1 : 0;
    });
  }

  return options;
};

export const societyCompaniesToSelectOptions = (companies?: ConfigCore['societyCompanies']): Option<string>[] => {
  if (!companies) return [];

  return companies.map(({ id, name }) => ({ label: name, id: String(id) }));
};

export const societiesToSelectOptions = (list?: { id: Id; name: string }[]): Option<string>[] => {
  if (!list) return [];

  return list.map((el) => {
    return {
      id: String(el.id),
      label: el.name,
    };
  });
};

export const getYearOptions = (yearsBefore = 30, yearsAfter = 30): Option[] => {
  const currentYear = new Date().getFullYear();
  const years: Option[] = [];
  let startYear = currentYear - yearsBefore;
  const endYear = currentYear + yearsAfter;

  while (startYear <= endYear) {
    years.push({ id: startYear.toString(), label: startYear.toString() });
    startYear++;
  }
  return years;
};

export const getGroupedOptions = <TGroupKeys extends string, TValues extends string>(
  groupOptions: GroupOptions<TGroupKeys, TValues>,
  groupOrder: TGroupKeys[],
  options: Option<TValues>[]
): GroupedOption<TValues>[] =>
  groupOrder.reduce((result: GroupedOption<TValues>[], key) => {
    if (groupOptions[key]) {
      result.push({
        label: groupOptions[key]?.label || '',
        options: options.filter((option) => {
          return groupOptions[key].keys.includes(option.id);
        }),
      });
    }

    return result;
  }, []);

export const getGroupOptions = <TGroupKeys extends string, TValues extends string>(
  groupOptions: GroupOptions<TGroupKeys, TValues>,
  groupOrder: TGroupKeys[],
  options: Option<TValues>[]
): GroupedOption<TValues>[] =>
  groupOrder.reduce((result: GroupedOption<TValues>[], key) => {
    if (groupOptions[key]) {
      result.push({
        label: groupOptions[key]?.label || '',
        options: options.filter((option) => groupOptions[key].keys.includes(option.id)),
      });
    }

    return result;
  }, []);

export const groupOptionsByBuildingType = (constants: ConfigAgencyConstants, user: IUser): GroupedOption<string>[] =>
  getGroupOptions(
    constants.buildingTypeGroups,
    constants.buildingTypeGroupOrder,
    buildingTypesToVisibleOptions(user.organisation?.settings?.building_types_visible || [], constants.buildingTypes)
  );

export const groupOptionsBySocietyBuildingType = (constants: ConfigAgencyConstants): GroupedOption<string>[] =>
  getGroupOptions(
    constants.societyBuildingTypeGroups,
    constants.societyBuildingTypeGroupOrder,
    objectToOptions(constants.societyPropertyTypes)
  );

export const findOptionLabelById = (id: Id, options: OptionsItem<Id>[]) =>
  options.find((option) => option.id === id)?.label || '';
